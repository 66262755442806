<template>
	<div class="w-full flex justify-center items-center relative">
		<img src="../../../../assets/public/images/how-works-thumb-2.png"/>
		<button class="absolute" @click="open = true">
			<img src="../../../../assets/public/images/icon-play.png"/>
		</button>
	</div>
	<YoutubePlayer :open="open" :onClose="() => open = false" :video_id="video_id"/>
</template>

<script>
import YoutubePlayer from '../../../organisms/Modals/YoutubePlayer/YoutubePlayer.vue';
import {ref} from 'vue'
/**
 * - Use it to show a content with background image
 */
export default {
	components: {
    YoutubePlayer
	},
	props: {
		video_id: {
			type: String,
			default: ''
		}
	},
	data() {
		const open = ref(false);
		return {
			open
		}
	}
}

</script>
