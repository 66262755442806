<template>
	<TransitionRoot as="template" :show="openModal">
		<Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="() => {openModal = false; onClose()}">
			<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
												 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild as="template" enter="ease-out duration-300"
												 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
												 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
												 leave-from="opacity-100 translate-y-0 sm:scale-100"
												 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
					<div
							class="relative inline-block align-bottom text-left overflow-hidden transform transition-all sm:align-middle">
							<div class="w-full flex justify-end items-end mb-2 p-1">
								<Circular class="bg-opacity-0 border-none outline-none" padding="2" iconName="XIcon" hoverBgColor="primary" :onClick="() => {openModal = false; onClose()}"/>
							</div>
							<YoutubeVue3 ref="youtube" :videoid="'3nccfSTEUXY'" :loop="1" :autoplay="1"/>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import {ref} from 'vue'
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import { YoutubeVue3 } from 'youtube-vue3'
import Circular from '../../../molecules/Button/Circular/Circular.vue'

/**
 * - Use it to show a simple youtube player modal.
 */
export default {
	components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Icon,
    YoutubeVue3,
    Circular
},
watch: {
	open: function (val) {
		this.openModal = ref(val)
	}
},
	props: {
		/**
		 * use it for youtube video player
		 */
		video_id: {
			type: String,
			default: '3nccfSTEUXY'
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		open: {
			type: Boolean,
			default: true
		}
	},
	data() {
		const openModal = ref(this.open)
		return {
			openModal,
		}
	}
}
</script>
